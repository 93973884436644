export class HomePage {
    OFFER_CARAOUSEL = {
        ID: '#offer-section-carousel',
        CLASS: '.offer-section-carousel'
    }

    CAMPAIGN_SECTION = {
        ID: '#campaign-section'
    }
    HP_OFFER_SECTIONS = {
        TODAYS_OFFERS: 'todays_offers',
        LATEST_OFFERS: 'latest_offers',
        CAMPAIGN_SECTION: 'campaign',
        PRODUCER_SECTION: 'producer'
    }
    curSectionId = null
    curOfferSection = ''
    hpOfferSectionData = {}
    curActiveMenuId = '#hp_section_todays_ofers'

    offerCarousel
    carouselConfig
    offerCarouselConfig

    offerCarouselConfig = {
        lazyLoad: true,
        loop: false,
        dots: true,
        nav: true,
        margin: 10,
        stagePadding: 5,
        navRewind: false,
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            480: {
                items: 1,
                nav: false
            },
            600: {
                items: 2,
                nav: false
            },
            800: {
                items: 3,
                dots: false
            },

            1000: {
                items: 4,
                margin: 15,
                dots: false
            },
            1100: {
                items: 5,
                margin: 15,
                dots: false
            }
        }
    }

    init() {
        if (window.location.hash == `#${this.HP_OFFER_SECTIONS.LATEST_OFFERS}`)
            this.curOfferSection = this.HP_OFFER_SECTIONS.LATEST_OFFERS
        this.initCarouselElement()
        this.initOfferCarousel()
        setTimeout(() => {
            // this.loadHpOfferSection(this.HP_OFFER_SECTIONS.LATEST_OFFERS)
            this.populateProducerSection()
        }, 250)
    }

    initCarouselElement() {
        this.videoCarousel = $j('.video-section-carousel')
        this.expertiseCarousel = $j('.expertise-carousel')
    }

    toggleRead(event) {
        $j(event.currentTarget.offsetParent.offsetParent.offsetParent).toggleClass('not-hidden')
        event.currentTarget.innerText =
            event.currentTarget.offsetParent.offsetParent.offsetParent.classList.contains(
                'not-hidden'
            )
                ? 'Read Less'
                : 'Read More'
        event.stopPropagation()
    }

    initOfferCarousel() {
        this.offerCarousel = $j(this.OFFER_CARAOUSEL.CLASS).owlCarousel(this.offerCarouselConfig)

        this.offerCarousel.on('initialized.owl.carousel', function (e) {
            $j(e.target).toggleClass('hide-nav', e.item.count <= e.page.size)
        })

        this.offerCarousel.on('resized.owl.carousel', function (e) {
            $j(e.target).toggleClass('hide-nav', e.item.count <= e.page.size)
        })
    }

    destroyOfferCarousel() {
        this.offerCarousel.trigger('destroy.owl.carousel')
    }

    populateProducerData(data) {
        const producerId = data.producerId
        const producerElem = $j(`#producer_${producerId}`)
        producerElem
            .find(`#producer_lp_${producerId}`)
            .removeClass('loading-dots')
            .html(data.lowestPrice)
        producerElem
            .find(`#producer_hp_${producerId}`)
            .removeClass('loading-dots')
            .html(data.highestPrice)
        producerElem
            .find(`#producer_qty_${producerId}`)
            .removeClass('loading-dots')
            .html(`${data.productsCount} products available`)
    }

    async populateProducerSection() {
        const $producerSectionData = await this.getHpOfferSection(
            this.HP_OFFER_SECTIONS.PRODUCER_SECTION
        )

        $producerSectionData.forEach(producerData => {
            this.populateProducerData(producerData)
        })
    }

    setHpOfferSection($section, data) {
        this.hpOfferSectionData[$section] = data
    }

    async getHpOfferSection($section) {
        const $hpSectionData =
            this.hpOfferSectionData[$section] ?? (await this.loadHpOfferSection($section))
        return $hpSectionData
    }

    async loadHpOfferSection(section) {
        let response = null

        const options = {
            url: this.getUrl('/cru_personalizer/ajax/getHomePageSection'),
            data: {
                section
            },
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            response = await this.callAjax(options)

            const res = JSON.parse(response)

            if (res.success) {
                this.hpOfferSectionData[section] = res.data
            } else if (res.error) {
                response = null
                console.error(response)
            }
        } catch (err) {
            console.error(response)
            response = null
        }
        return this.hpOfferSectionData[section] ?? null
    }

    getCmProductUrl(isMainSite) {
        const subDomain = location.host.substring(0, 2)
        const topLevelDomain = location.host.replace(subDomain, '')
        let cmUrl = ''
        if (!isMainSite) {
            cmUrl = `https://markets${topLevelDomain}/${subDomain}/product`
        }
        return cmUrl
    }

    redirectToUrl(url, cmUrl) {
        let cmProductUrl = `${cmUrl}${url}`
        window.location.assign(cmProductUrl)
    }

    getItemHtml(item, cmUrl) {
        let itemImg = window.innerWidth > 800 ? item.imageUrl : item.mobileImageUrl
        let productUrl = item.url.includes('/product')  ? item.url.replace('/product', '') : item.url
        cmUrl = item.url.includes('/buy') || item.url.includes('/offer') || item.url.includes('/cat') || item.url.includes('/blog') || item.url.includes('/cru-world')? cmUrl.replace('/product', '') : cmUrl
        return `<div class="offer-card-container" 
            onclick="currentResolver.homeHandler.redirectToUrl('${productUrl}','${cmUrl}')" ><div 
                class="offer-card ${item.isInvNote ? 'inv-note-background' : ''}"  style="${
            item.isInvNote ? `background-image:url(${itemImg})` : ''
        } ">
                    <div>
                        <div>
                            <h3>${item.title}</h3>
                            
                            ${
                                !item.isInvNote
                                    ? `<a class="btn ${
                                          item.isSold ? 'btn-cru-red' : 'btn-blue'
                                      } small-screen-label" href="${
                                          !item.isSold ? cmUrl + productUrl : ''
                                      }">
                                        ${item.qtyText}
                                        </a>`
                                    : ''
                            }
                        </div>
                        ${
                            item.tag
                                ? `<button class="btn ${item.tag.color ? 'tag-teal' : 'tag-red'}" ${
                                      item.tag.label_link
                                          ? `onClick="currentResolver.homeHandler.redirectToUrl('${item.tag.label_link}','${cmUrl}')"`
                                          : ''
                                  }>${item.tag.label_name}</button>`
                                : ''
                        }
                    </div>
                    
                    ${
                        !item.isInvNote
                            ? `<div class="offer-img-container"><img src="${
                                  item.imageUrl
                              }" onerror="handleImageError.call(this)"></div>
                                <a class="btn ${item.isSold ? 'btn-cru-red' : 'btn-blue'}" href="${
                                  !item.isSold ? cmUrl + productUrl : ''
                              }">${item.qtyText}</a>`
                            : ''
                    }
                    <div class="hover-card">
                        <div>
                            <div class="more-text-container">
                                <div>
                                    ${item.desc}
                                </div>
                                <a id="read-btn" onclick="currentResolver.homeHandler.toggleRead(event)">Read More</a>
                            </div>
                            <a
                                class="btn btn-teal"
                                href="${item.isInvNote ? productUrl : cmUrl + productUrl}"
                            >
                                ${item.isInvNote ? 'Read Now' : 'View Product'}
                            </a>
                        </div>
                    </div>
                </div></div>`
    }

    async getCampaignSectionHtml($id) {
        const $campaignData = await this.getHpOfferSection(this.HP_OFFER_SECTIONS.CAMPAIGN_SECTION)

        let $campaignHtml = 'Nothing to preview'
        if ($campaignData) {
            const $curCampaign = $campaignData.filter(data => {
                return data['data']['id'] == $id
            })

            const $curCampaignData = $curCampaign[0] ? $curCampaign[0]['data'] : null

            $campaignHtml = $curCampaignData
                ? `<a href="${$curCampaignData.url}">
                        <img src="${$curCampaignData.imageUrl}" class="campaign-desktop-img"/>
                        <img src="${$curCampaignData.mobileImageUrl}" class="campaign-mobile-img" />
                    </a>`
                : 'Nothing to preview'
        }

        return $campaignHtml
    }

    changeSection(curSection, id = null) {
        if (curSection != this.curOfferSection || id != this.curSectionId) {
            this.activateHpOfferSection(curSection, id)
        }
    }

    async populateOfferSection($curSection, $id = null) {
        if (this.curOfferSection != this.HP_OFFER_SECTIONS.CAMPAIGN_SECTION) {
            const sectionItems = await this.getHpOfferSection($curSection)
            let secHtml = ''
            sectionItems.forEach(sectionItem => {
                let cmUrl = this.getCmProductUrl(sectionItem.isMainSite)
                secHtml += this.getItemHtml(sectionItem, cmUrl)
            })

            this.destroyOfferCarousel()
            // First destroy and then reinitialize
            $j(this.OFFER_CARAOUSEL.ID).html(secHtml)
            $j(this.OFFER_CARAOUSEL.ID).show()
            $j(this.CAMPAIGN_SECTION.ID).hide()
            this.initOfferCarousel()
            $j(this.OFFER_CARAOUSEL.ID).removeClass('owl-hidden')
        } else {
            const campaignHtml = await this.getCampaignSectionHtml($id)
            $j(this.CAMPAIGN_SECTION.ID).html(campaignHtml)
            $j(this.CAMPAIGN_SECTION.ID).show()
            $j(this.OFFER_CARAOUSEL.ID).hide()
        }
    }

    activateMenu(curSection, id = null) {
        const $curMenu = $j(this.curActiveMenuId)
        const $newMenuId = id ? `#hp_menu_${curSection}${id}` : `#hp_menu_${curSection}`
        const $newMenu = $j($newMenuId)

        $curMenu.removeClass('selected')
        $newMenu.addClass('selected')
        this.curActiveMenuId = $newMenuId
    }

    async activateHpOfferSection(curSection, id = null) {
        this.activateMenu(curSection, id)
        this.curOfferSection = curSection
        await this.populateOfferSection(curSection, id)
        this.curSectionId = id
    }

    async callAjax(options) {
        try {
            return await $j.ajax(options)
        } catch (error) {
            return null
        }
    }

    getUrl(path) {
        return `${window.location.origin}${path}`
    }

    scrollToSection(sectionId) {
        $j('html,body').animate({ scrollTop: $j(`#${sectionId}`).offset().top - 100 }, 'slow')
        this.changeSection(this.HP_OFFER_SECTIONS.LATEST_OFFERS)
    }
}
